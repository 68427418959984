import ToastificationContent from '../../components/toastification/ToastificationContent.vue'
import authConfigs from './authConfig'
import globalConfigs from '../../../configs/configs'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // authConfig <= Will be used by this service
  authConfig = { ...authConfigs }

  // globalConfig <= Will be used by this service
  globalConfigs = { ...globalConfigs }

  toast = { ...ToastificationContent }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.authConfig = { ...this.authConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.authConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.logout()
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.authConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.authConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.authConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.authConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/auth/login`, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.authConfig.registerEndpoint, ...args)
  }

  forgotPassword(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/auth/forgot-password`, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.authConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  logout() {
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    localStorage.removeItem(this.authConfig.storageTokenKeyName)
    localStorage.removeItem(this.authConfig.storageRefreshTokenKeyName)

    // Remove userData from localStorage
    localStorage.removeItem('userData')

    // Redirect to login page
    window.location.href = 'login'
  }

  fetchCategories() {
    return this.axiosIns.get(`${this.globalConfigs.API_ENDPOINT}/categories?countyId=10`)
  }

  fetchCollectionCenters(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/fetch/collection-centers`, ...args)
  }

  payForDailyParking(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/pay/daily-parking`, ...args)
  }

  payForCess(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/pay/cess`, ...args)
  }

  payForSbp(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/pay/sbp`, ...args)
  }

  fetchBusinesses(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/fetch/business-list`, ...args)
  }

  fetchBusinessData(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/fetch/business-data`, ...args)
  }

  downloadInvoice(id) {
    window.open(`${this.globalConfigs.API_ENDPOINT}/download/sbp-invoice/${id}`, '_blank')
  }

  downloadReceipt(id) {
    window.open(`${this.globalConfigs.API_ENDPOINT}/download/sbp-receipt/${id}`, '_blank')
  }

  downloadPermit(id) {
    window.open(`${this.globalConfigs.API_ENDPOINT}/download/sbp-permit/${id}`, '_blank')
  }

  fetchTransactions(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/fetch/transactions`, ...args)
  }

  getUserProfile() {
    return this.axiosIns.get(`${this.globalConfigs.API_ENDPOINT}/profile`)
  }

  fetchServicePrice(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/fetch/service-price`, ...args)
  }

  fetchCessServices(...args) {
    return this.axiosIns.post(`${this.globalConfigs.API_ENDPOINT}/fetch/cess-services`, ...args)
  }

  testSite(...args) {
    return this.axiosIns.get('https://techlibrary.hpe.com/device_help/OfficeConnect/portal/1.0/content/getting%20started/unreachable%20site.htm', ...args)
  }
}
